import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Obfuscate from 'react-obfuscate';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

const HomePageStyles = styled.main`
  display: flex;
  margin-bottom: 5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ContentStyles = styled.article`
  flex-basis: 660px;
  flex-grow: 2;
  border-right: 1px solid #e8e8e8;
  padding-top: 4rem;
  justify-content: flex-start;
  > * {
    margin-left: 4rem;
    margin-right: 3rem;
  }
`;

export default function HomePage() {
  return (
    <>
      <GatsbySeo
        title="Impressum"
        description="Pflichtangaben nach § 5 Telemediengesetz"
        noindex
      />
      <Header />
      <HomePageStyles>
        <ContentStyles>
          <h1>Impressum</h1>
          <h2>Pflichtangaben nach § 5 Telemediengesetz:</h2>
          <h3>Verantwortlich für den Inhalt dieser Seiten</h3>
          <p>Dipl.-Psych. Sarah Such und Dipl.-Psych. Florian Such</p>
          <h3>Name und Sitz der Berufsausübungsgemeinschaft</h3>
          <p>
            Praxis für Psychotherapie
            <br />
            Gemeinschaftspraxis (in Form einer GbR)
            <br />
            Kornhausgasse 3<br />
            88212 Ravensburg
            <br />
            <strong>Telefon: </strong>
            <Obfuscate
              tel="00497519770715"
              obfuscateChildren={false}
              linkText="https://psychotherapieravensburg.de/datenschutz/"
            >
              <span style={{ display: 'inline-block' }}>0751 / 977 707 15</span>
            </Obfuscate>
            <br />
            <strong>E-Mail: </strong>
            <Obfuscate
              email="kontakt@psychotherapieravensburg.de"
              linkText="https://psychotherapieravensburg.de/datenschutz/"
            />
            <br />
            <strong>Internet:</strong>{' '}
            <Link to="/">ravensburgpsychotherapie.de</Link>
          </p>
          <h3>
            Gesetzliche Berufsbezeichnungen und sozialrechtliche Zulassung
          </h3>
          <p>
            <strong>Sarah Such</strong>
          </p>
          <ul>
            <li>
              Der akademische Grad Diplom-Psychologin wurde von der Universität
              Konstanz im Jahr 2010 verliehen.
            </li>
            <li>
              Die Approbation als Psychologische Psychotherapeutin wurde im Jahr
              2015 in der Bundesrepublik Deutschland durch das
              Regierungspräsidium Stuttgart verliehen und berechtigt zur
              unbeschränkten, eigenständigen Ausübung der heilkundlichen
              Psychotherapie im Sinne des § 1 Abs. 3 Satz 1 des
              Psychotherapeutengesetzes (PsychThG) mit Fachkunde in
              Verhaltenstherapie.
            </li>
            <li>
              Eintrag im Arztregister Baden-Württemberg in Freiburg, Lebenslange
              Arztnummer (LANR): 498048368
            </li>
            <li>
              Zulassung zur Teilnahme an der vertrags-psychotherapeutischen
              Versorgung durch die Kassenärztliche Vereinigung
              Baden-Württemberg, Haldenhaustr.11, 72770 Reutlingen (
              <a href="https://kvbawue.de">www.kvbawue.de</a>) zum 01.01.2021.
              Betriebsstättennummer: BSNR 626949100
            </li>
          </ul>
          <p>
            <strong>Florian Such</strong>
          </p>
          <ul>
            <li>
              Der akademische Grad Diplom-Psychologe wurde im Jahr 2011 von der
              Universität Konstanz verliehen
            </li>
            <li>
              Die Approbation als Psychologischer Psychotherapeut wurde im Jahr
              2020 in der Bundesrepublik Deutschland durch das
              Regierungspräsidium Stuttgart verliehen und berechtigt zur
              unbeschränkten, eigenständigen Ausübung der heilkundlichen
              Psychotherapie im Sinne des § 1 Abs. 3 Satz 1 des
              Psychotherapeutengesetzes (PsychThG) mit Fachkunde in
              Verhaltenstherapie.
            </li>
            <li>
              Eintrag im Arztregister Baden-Württemberg in Freiburg, Lebenslange
              Arztnummer (LANR): 678105768
            </li>
            <li>
              Zulassung zur Teilnahme an der vertrags-psychotherapeutischen
              Versorgung durch die Kassenärztliche Vereinigung
              Baden-Württemberg, Haldenhaustr.11, 72770 Reutlingen (
              <a href="https://kvbawue.de">www.kvbawue.de</a>) zum 01.01.2021.
              Betriebsstättennummer: BSNR 626949100
            </li>
          </ul>
          <h3>Aufsichtsbehörde gem. § 6 Nr. 3 TDG</h3>
          <p>
            Landespsychotherapeutenkammer Baden-Württemberg, Jägerstr. 40, 70174
            Stuttgart; Telefon:{' '}
            <Obfuscate
              tel="0711-674470-0"
              linkText="https://psychotherapieravensburg.de/datenschutz/"
              style={{ textDecoration: 'none' }}
            />
            , Fax: 0711-674470-15,{' '}
            <a href="https://www.lpk-bw.de">www.lpk-bw.de</a>
          </p>
          <h3>Berufsrechtliche Regelungen</h3>
          <p>
            Die Texte sind im Buchhandel und bei den jeweiligen
            Behörden/Kammern/Vereinigungen verfügbar und im Internet abrufbar:
          </p>
          <ul>
            <li>
              Gesetz über die Berufe des Psychologischen Psychotherapeuten und
              des Kinder- und Jugendlichen Psychotherapeuten
              (Psychotherapeutengesetz PsychThG), siehe{' '}
              <a href="https://www.bptk.de/recht/gesetze-verordnungen/">
                Bundespsychotherapeutenkammer
              </a>
            </li>
            <li>
              Berufsordnung für die Psychologischen Psychotherapeuten und für
              die Kinder- und Jugendlichen Psychotherapeuten, siehe{' '}
              <a href="https://www.lpk-bw.de/fachportal/berufsrecht">
                Landespsychotherapeutenkammer Baden-Württemberg
              </a>
            </li>
            <li>
              Gebührenordnung für Psychologische Psychotherapeuten, siehe{' '}
              <a href="https://www.bptk.de/recht/verguetung/">
                Bundespsychotherapeutenkammer
              </a>
            </li>
            <li>
              Landesgesundheitsdienstgesetz, siehe{' '}
              <a href="https://www.gesundheitsdialog-bw.de/zukunftsplan-gesundheit/grundlagen/landesgesundheitsgesetz/">
                gesundheitsdialog-bw.de
              </a>
            </li>
            <li>
              Landesheilberufegesetz, siehe{' '}
              <a href="http://www.landesrecht-bw.de/jportal/portal/t/eh6/page/bsbawueprod.psml?doc.hl=1&doc.id=jlr-HeilBKGBW1995rahmen%3Ajuris-lr00&documentnumber=1&numberofresults=112&showdoccase=1&doc.part=X&paramfromHL=true ">
                landesrecht-bw.de
              </a>
            </li>
            <li>
              Patientenrechtegesetz, siehe{' '}
              <a href="https://www.bundesaerztekammer.de/recht/gesetze-und-verordnungen/patientenrechtegesetz/">
                Bundesärztekammer
              </a>{' '}
            </li>
          </ul>
          <h3>Umsatzsteuer-Identifikations-Nr.</h3>
          <p>
            entfällt gemäß §4 Nr. 14 Umsatzsteuergesetz für heilkundliche
            psychotherapeutische Leistungen.
          </p>
          <h3>Hinweise zur Kommunikation per E-Mail</h3>
          <p>
            Die Kommunikation per E-Mail kann Sicherheitslücken aufweisen.
            Sofern Sie uns per E-Mail kontaktieren, gehen wir davon aus, dass
            wir befugt sind, Ihnen auf diesem Weg zu antworten. Falls Sie damit
            nicht einverstanden sind, bitten wir um Ihren ausdrücklichen
            Hinweis. Entsprechendes gilt für die Kommunikation per Mobiltelefon.
          </p>
          <h3>Hinweise zum Haftungsausschluss und Rechten</h3>
          <p>
            Die Inhalte wurden von uns sorgfältig kontrolliert. Wir übernehmen
            jedoch keine Haftung für die Inhalte externer Links. Für den Inhalt
            der verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich. Die hier angebotenen Informationen stellen keine
            Beratung oder heilkundliche Behandlung dar, diese entsteht erst im
            persönlichen Kontakt. Rechte und Pflichten zwischen uns und dem
            Nutzer der Webpage oder Dritten sind ausgeschlossen. Die Inhalte
            dieser Website sind urheberrechtlich geschützt, eine
            Vervielfältigung oder Verwendung der Texte, Photos und Grafiken in
            anderen elektronischen oder gedruckten Publikationen ist ohne
            schriftliche Zustimmung der Autoren nicht gestattet. Bei aller
            Sorgfalt wird keinerlei Gewähr für die Aktualität, Korrektheit,
            Vollständigkeit oder Qualität der bereitgestellten Informationen
            übernommen. Haftungsansprüche gegen die Autoren, welche sich auf
            Schäden materieller oder ideeller Art beziehen, die durch die
            Nutzung der dargebotenen (auch fehlerhaften oder unvollständigen)
            Informationen verursacht wurden, sind grundsätzlich ausgeschlossen,
            sofern seitens der Autoren kein nachweislich vorsätzliches oder grob
            fahrlässiges Verschulden vorliegt.{' '}
          </p>
          <h3>Website</h3>
          <p>
            <strong>Umsetzung: </strong>Aaron Steinke
            <br />
            <strong>Design: </strong>Andreas Maschke
          </p>
        </ContentStyles>
        <Sidebar />
      </HomePageStyles>
    </>
  );
}
